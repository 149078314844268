var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CCard',{staticStyle:{"margin-top":"20px"}},[_c('CCardHeader',{staticClass:"bg-warning text-white"},[_c('strong',[_vm._v("บันทึกพฤติกรรมของฉัน")])]),(_vm.rows)?_c('CCardBody',[(_vm.grandTotal)?_c('CCol',{attrs:{"lg":"12"}},[_vm._v(" คะแนนพฤติกรรม: "+_vm._s(_vm.grandTotal)+" คะแนน ")]):_vm._e(),_c('CCol',{attrs:{"lg":"12"}},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"search-options":{ enabled: true },"sort-options":{
          enabled: true,
          initialSortBy: { field: 'att_id', type: 'desc' },
        },"pagination-options":{
          enabled: true,
          mode: 'page',
          perPage: 10,
        }}},[_vm._v(" > ")])],1)],1):((_vm.rows = []))?_c('CCardBody',[_c('div',{staticClass:"spinner-border text-secondary",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"})]),_c('div',[_vm._v("Loading...")])]):_vm._e(),_c('CModal',{attrs:{"show":_vm.modalAlert,"no-close-on-backdrop":true,"centered":true,"title":"Modal title 2","size":"lg","color":"danger"},on:{"update:show":function($event){_vm.modalAlert=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h6',{staticClass:"modal-title"},[_c('h3',{staticClass:"text-3xl font-semibold"},[_vm._v("คำเตือน")])]),_c('CButtonClose',{staticClass:"text-white",on:{"click":function($event){_vm.modalAlert = false}}})]},proxy:true},{key:"footer",fn:function(){return [_c('CButton',{attrs:{"color":"danger"},on:{"click":function($event){_vm.modalAlert = false}}},[_vm._v("Discard")])]},proxy:true}])},[_vm._v(" "+_vm._s(_vm.msgAlert)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }