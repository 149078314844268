<template>
  <div>
    <!------OFFICER AREA--------->
    <TblPendingSummary v-if="userData.user_address" />
    <TblInvoiceBySchool v-if="userData.user_address" />
    <TblInvoiceByDate v-if="userData.user_address" />
    <CRow v-if="userData.user_address && (userData.user_level).search('budgetOfficer')>=0">
      <CardCreateQRbyLevel
    /></CRow>
    <TblPendingSummaryByClass v-if="userData.user_address" />

    <!------STUDENT AREA--------->
    <TblInvoiceByStudent v-if="userData.std_ordinal" />
  </div>
</template>

<script>
import WidgetStd from "./widgets/WidgetStd.vue";
import TblMyScore from "./components/TblMyScore";
import TblAttendanceByTeacher from "./components/TblAttendanceByTeacher";
import TblInvoiceByStudent from "./components/TblInvoiceByStudent";
import TblPendingSummary from "./components/TblPendingSummary";
import TblPendingSummaryByClass from "./components/TblPendingSummaryByClass";
import TblInvoiceBySchool from "./components/TblInvoiceBySchool";
import TblInvoiceByDate from "./components/TblInvoiceByDate";
import CardCreateQRbyLevel from "./components/CardCreateQRbyLevel";

export default {
  name: "Dashboard",
  components: {
    WidgetStd,
    TblAttendanceByTeacher,
    TblMyScore,
    TblInvoiceByStudent,
    TblPendingSummary,
    TblPendingSummaryByClass,
    TblInvoiceBySchool,
    TblInvoiceByDate,
    CardCreateQRbyLevel,
  },
  data() {
    return {
      selected: "Month",
      userData: this.$cookies.get("user"),
    };
  },
  methods: {},
  mounted() {
    //console.log(this.userData);
  },
};
</script>
