<template>
  <CCol lg="12">
    <CCard>
      <CCardHeader class="bg-secondary"
        >สรุปรายการ
        <div class="text-right">
          <h3 v-if="sumAmount > 0" class="text-danger">
            ยอดค้างชำระ: {{ Number(sumAmount).toLocaleString() }} บาท
          </h3>
          <h3 v-else class="text-success">
            ยอดค้างชำระ: {{ Number(sumAmount).toLocaleString() }} บาท
          </h3>
        </div>
      </CCardHeader>

      <CCardBody v-if="rows">
        <CRow>
          <CCol lg="6" v-for="invoice in rows" :key="invoice.id">
            <CCard>
              <CCardHeader class="bg-yellow">
                เลขที่ invoice: {{ invoice.invoice_id }}
              </CCardHeader>
              <CCardBody>
                เลขประจำตัวนักเรียน: {{ invoice.invoice_std_id }}<br />
                ชื่อ นามสกุล: {{ invoice.prefix_name }}{{ invoice.std_name }}
                {{ invoice.std_surname }}<br />
                รายการ: {{ invoice.invoice_content }}<br />
                จำนวน:
                {{ Number(invoice.invoice_amount).toLocaleString() }} บาท<br />
                สถานะ:
                <span v-if="invoice.invoice_status == 1" class="text-danger">{{
                  invoice.paymant_name
                }}</span>
                <span v-else class="text-success">{{
                  invoice.paymant_name
                }}</span>
                <div v-if="invoice.invoice_status == 1" class="text-center">
                  <img
                    :src="
                      'https://vrsim4learning.com/beamingschoolNeAPI/uploads/invoice9/' +
                      invoice.std_level_code +
                      '_' +
                      invoice.std_class +
                      '_' +
                      invoice.invoice_std_id +
                      invoice.invoice_id +
                      '.png'
                    "
                  />
                </div>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CCardBody>
      <CCardFooter class="text-right"> </CCardFooter>
    </CCard>

    <!--MODAL SECTION---------------------------------------------------------------------------->
  </CCol>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";

export default {
  components: {
    Datepicker,
    vSelect,
    VueGoodTable,
  },
  data() {
    return {
      userData: this.$cookies.get("user"),
      invoiceInfo: null,
      selectedInvoice: null,
      responseContent: null,
      showModal: false,
      modalSuccess: null,
      newCiteria: null,
      darkModal: false,

      rows: null,
      sumAmount: null,
      uploadFiles: [],
      activeTab: 0,
      enableUpload: false,
    };
  },
  mounted() {
    //console.log(this.userData);
    //FETCH INVOICE INFO BY STD
    let formData = new FormData();
    formData.append("txtAction", "getInvoice");
    formData.append("qStd", this.userData.stud_id);
    formData.append("schoolCode", this.userData.std_school);
    this.axios
      .post(this.$hostUrl + "php_action/invoiceAPI.php", formData)
      .then((response) => {
        //console.log(response.data);
        this.rows = response.data.mainData;
        this.sumAmount = response.data.sumPending;
      });
  },
  methods: {
    sendData() {
      const form = document.getElementById("formCreateAbsense");
      const formData = new FormData(form);
      formData.append("txtAction", "create");
      formData.append("selectedSemester", this.selectedSemester.code);
      formData.append("selectedSubject", this.selectedSubject.code);
      formData.append("txtReason", this.selectedCiteria.code);
      let jsonArr = JSON.stringify(this.selectedStudent);
      formData.append("selectedStudent", jsonArr);
      this.axios
        .post(this.$hostUrl + "php_action/attendanceAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.error == "") {
            this.showModal = true;
            this.modalSuccess = true;
            this.responseContent = "บันทึกสำเร็จ";
            alert(this.responseContent);
            location.reload();
          } else {
            this.responseContent = response.data.error;
            this.showModal = true;
            this.modalSuccess = false;
            alert(this.responseContent);
            location.reload();
          }
        })
        .finally(() => {});
    },
    toggleModal: function () {
      this.showModal = !this.showModal;
      location.reload();
    },
    openModal(data) {
      this.uploadFiles = [];
      this.darkModal = true;
      //FETCH SELECTED INVOICE
      this.axios
        .get(
          this.$hostUrl +
            "php_action/invoiceAPI.php?txtAction=getInvoice&qInvoice=" +
            data
        )
        .then((response) => {
          //console.log(response.data);
          this.selectedInvoice = response.data.mainData;
        })
        .finally(() => {});
    },
    closeModal() {
      location.reload();
    },
    addReceipt() {
      const form = document.getElementById("formUploadReceipt");
      const formData = new FormData(form);
      formData.append("txtAction", "create");
      if (this.uploadFiles | (this.uploadFiles.length == 0)) {
        alert("ต้องอัพโหลดรูปภาพอย่างน้อย 1 รูป");
        return false;
      }
      formData.append("file", this.uploadFiles[0]);
      this.axios
        .post(this.$hostUrl + "php_action/invoiceAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.createState == "success") {
            this.responseContent = "บันทึกสำเร็จ";
            alert(this.responseContent);
            location.reload();
          } else {
            this.responseContent = response.data.error;
            alert(this.responseContent);
            //location.reload();
          }
        })
        .finally(() => {});
    },
    handleImages(files) {
      this.uploadFiles = files;
      //console.log(this.uploadFiles);
    },
  },
};
</script>
<style scoped>
.ui-input-text {
  border-color: transparent;
}
</style>
