<template>
  <CCard style="margin-top: 20px">
    <CCardHeader class="bg-warning text-white"
      ><strong>บันทึกพฤติกรรมของฉัน</strong>
    </CCardHeader>
    <CCardBody v-if="rows">
      <CCol lg="12" v-if="grandTotal">
        คะแนนพฤติกรรม: {{ grandTotal }} คะแนน
      </CCol>
      <CCol lg="12">
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :search-options="{ enabled: true }"
          :sort-options="{
            enabled: true,
            initialSortBy: { field: 'att_id', type: 'desc' },
          }"
          :pagination-options="{
            enabled: true,
            mode: 'page',
            perPage: 10,
          }"
        >
          >
        </vue-good-table>
      </CCol>
    </CCardBody>
    <CCardBody v-else-if="(rows = [])">
      <div class="spinner-border text-secondary" role="status">
        <span class="sr-only"></span>
      </div>
      <div>Loading...</div>
    </CCardBody>

    <!-------------------MODAL ALERT------------------------>
    <CModal
      :show.sync="modalAlert"
      :no-close-on-backdrop="true"
      :centered="true"
      title="Modal title 2"
      size="lg"
      color="danger"
    >
      {{ msgAlert }}
      <template #header>
        <h6 class="modal-title">
          <h3 class="text-3xl font-semibold">คำเตือน</h3>
        </h6>
        <CButtonClose @click="modalAlert = false" class="text-white" />
      </template>
      <template #footer>
        <CButton @click="modalAlert = false" color="danger">Discard</CButton>
      </template>
    </CModal>
  </CCard>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import XLSX from "xlsx"; // import xlsx;
import SltSubject from "./SltSubject.vue";
import SltStudent from "./SltStudent.vue";
import ChkPeriod from "./ChkPeriod.vue";
import usersData from "../users/UsersData";

export default {
  name: "Dashboard",
  components: {
    VueGoodTable,
    Datepicker,
    vSelect,
    XLSX,
    SltSubject,
    SltStudent,
    ChkPeriod,
  },
  data() {
    return {
      columns: [
        {
          label: "เลขที่",
          field: "att_id",
          type: "number",
        },
        {
          label: "วันที่",
          field: "abs_date",
        },
        {
          label: "คำนำหน้า",
          field: "std_title",
        },
        {
          label: "ชื่อ",
          field: "std_name",
        },
        {
          label: "นามสกุล",
          field: "std_surname",
        },
        {
          label: "วิชา/กิจกรรม/สถานที่",
          field: "subject_name",
        },
        {
          label: "ผู้รายงาน",
          field: "user_fname",
        },
        {
          label: "ประเภท",
          field: "tc_citeria_name",
        },
        {
          label: "คะแนน",
          field: "tc_citeria_score",
        },
      ],
      rows: [],

      userData: this.$cookies.get("user"),
      selectedEndDate: null,
      responseContent: null,
      darkModal: false,
      modalCreate: false,
      modalEdit: false,
      modalAlert: false,
      msgAlert: null,
      pictUrl1: "-",
      pictUrl2: "-",
      pictUrl3: "-",
      pictUrl4: "-",
      fileLink1: "-",
      fileLink2: "-",
      fileLink3: "-",
      fileLink4: "-",
      pic1New: null,
      pic2New: null,
      pic3New: null,
      pic4New: null,
      file1New: null,
      file2New: null,
      file3New: null,
      file4New: null,
      classInfo: null,
      grandTotal: null,
    };
  },
  created() {
    setInterval(() => {
      this.searchBeh();
    }, 1000*60);
  },
  mounted() {
    //console.log(this.userData);

    let schoolCode = this.userData.std_school;
    this.searchBeh();
  },
  methods: {
    searchBeh() {
      console.log("asking...");
      this.rows = [];
      //const form = document.getElementById("formSearchBeh");
      const formData = new FormData();
      formData.append("txtAction", "getBehScore");
      formData.append("txtUsername", this.userData.stud_id);
      formData.append("txtPassword", this.userData.stud_id);
      this.axios
        .post(this.$hostUrl + "php_action/attendanceAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log(response.data);
          if (response.data.mainData) {
            if (response.data.mainData.length > 0) {
              //login success and show data
              this.rows = response.data.mainData;
              // Cal sum
              let sum = 0;
              //console.log(this.rows[0].tc_citeria_score);
              for (let i = 0; i <= this.rows.length - 1; i++) {
                console.log(this.rows[i].tc_citeria_score);
                sum = sum + parseInt(this.rows[i].tc_citeria_score);
              }
              this.grandTotal = 100 + sum;
            } else {
              this.grandTotal = 100;
            }
          } else if (response.data.error == "") {
            //alert("here");
            //login fail
            this.darkModal = true;
          }
        });
    },
  },
};
</script>
