var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CCol',{attrs:{"lg":"12"}},[_c('CCard',[_c('CCardHeader',{staticClass:"bg-secondary"},[_vm._v("สรุปรายการ "),_c('div',{staticClass:"text-right"},[(_vm.sumAmount > 0)?_c('h3',{staticClass:"text-danger"},[_vm._v(" ยอดค้างชำระ: "+_vm._s(Number(_vm.sumAmount).toLocaleString())+" บาท ")]):_c('h3',{staticClass:"text-success"},[_vm._v(" ยอดค้างชำระ: "+_vm._s(Number(_vm.sumAmount).toLocaleString())+" บาท ")])])]),(_vm.rows)?_c('CCardBody',[_c('CRow',_vm._l((_vm.rows),function(invoice){return _c('CCol',{key:invoice.id,attrs:{"lg":"6"}},[_c('CCard',[_c('CCardHeader',{staticClass:"bg-yellow"},[_vm._v(" เลขที่ invoice: "+_vm._s(invoice.invoice_id)+" ")]),_c('CCardBody',[_vm._v(" เลขประจำตัวนักเรียน: "+_vm._s(invoice.invoice_std_id)),_c('br'),_vm._v(" ชื่อ นามสกุล: "+_vm._s(invoice.prefix_name)+_vm._s(invoice.std_name)+" "+_vm._s(invoice.std_surname)),_c('br'),_vm._v(" รายการ: "+_vm._s(invoice.invoice_content)),_c('br'),_vm._v(" จำนวน: "+_vm._s(Number(invoice.invoice_amount).toLocaleString())+" บาท"),_c('br'),_vm._v(" สถานะ: "),(invoice.invoice_status == 1)?_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(invoice.paymant_name))]):_c('span',{staticClass:"text-success"},[_vm._v(_vm._s(invoice.paymant_name))]),(invoice.invoice_status == 1)?_c('div',{staticClass:"text-center"},[_c('img',{attrs:{"src":'https://vrsim4learning.com/beamingschoolNeAPI/uploads/invoice9/' +
                    invoice.std_level_code +
                    '_' +
                    invoice.std_class +
                    '_' +
                    invoice.invoice_std_id +
                    invoice.invoice_id +
                    '.png'}})]):_vm._e()])],1)],1)}),1)],1):_vm._e(),_c('CCardFooter',{staticClass:"text-right"})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }